import { Heading3, Spinner } from '@ovotech/element';
import { WindowLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import styled from 'styled-components';

import { journeyAtom } from '@src/store/store';
import { BasicPage } from '@components/layout';
import { Journey } from '@src/types/Journey';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const StyledHeading3 = styled(Heading3)`
  ${({ theme }) => `
    color: ${theme.core.color.brand.black};
    padding-top: ${theme.core.space[8]};
    text-align: center;
  `}
`;

interface Props {
  location: WindowLocation;
}

export const ExcessSignpostRedirectPage = ({ location }: Props) => {
  const journey = useAtomValue(journeyAtom);

  useEffect(() => {
    setTimeout(() => {
      navigate(journey === Journey.InsuranceHighExcess ? '/' : '/95-excess');
    }, 1500);
  }, []);

  return (
    <BasicPage
      pageName="excess-signpost-redirect"
      location={location}
      shouldIncludeFootnotes={false}
    >
      <Container>
        <Spinner />
        <StyledHeading3>
          {journey === Journey.InsuranceHighExcess
            ? 'Heading to see more plans...'
            : 'Heading to the £95 excess page...'}
        </StyledHeading3>
      </Container>
    </BasicPage>
  );
};
